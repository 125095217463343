.addSection {
    background-color: #F2F2F2;
    padding-top: 80px;
    padding-bottom: 80px;
    margin-left: -20px;
    margin-right: -20px;
}

.addBottom {
    background: repeating-linear-gradient(
        transparent 0px 54px,
        var(--border-color) 0px 55px
    );
    height: 100%;
}

.greyText {
    color: #797676;
}

.studentEntry:hover {
    --bs-table-accent-bg: var(--bs-table-hover-bg)
}

.submission:hover {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    cursor: pointer;
}

.rotatedHeader {
    cursor: pointer;
    height: 20vh;
    position: relative;
}

.rotatedContent {
    transform: rotate(-90deg);
    transform-origin: left bottom;
    position: absolute;
    padding: 1rem;
    bottom: 0;
    left: 50%;
    width: 20vh;
    height: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rotatedHeader p {
    margin: auto; 
    overflow: hidden;
    text-overflow: ellipsis;
    // Might want later if gradebook allows for year switching
    // white-space: nowrap;
}

.height80 {
    height: 80%;
}

.unavailableAssignment {
    background-color: #E0E0E2;
}

.addClassContainerRow {
    width: 80% !important;
    margin: auto !important;
}

.addClassContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.addClassBtn {
    width: 9rem;
}

.checkAll > input {
    scale: 1.4 !important;
}

.assignmentCheckbox > input {
    scale: 1.3 !important;
}

.bulkItemsContainer {
    border: 1px solid lightgray;
    border-radius: 5px;
}

.selectedCount {
    position: absolute;
    top: -38%;
    right: -5%;
    font-size: medium;
    padding: 0.5px 9px;
}

.hiddenActions {
    opacity: 0;
    max-height: 0;
    transition: opacity 0.3s ease, max-height 0.3s ease;
}

.displayActions {
    opacity: 1;
    max-height: 100px;
    transition: opacity 0.3s ease, max-height 0.3s ease;
}

@media screen and (max-width: 992px) {
    .calendarContainer {
        margin-top: 1rem !important;
    }
}

@media screen and (min-width: 991px) {
    .actionItemsContainer {
        margin-bottom: -3rem;
    }
}

@media screen and (max-width: 800px) {
    .selectedCount {
        top: -40%;
        right: -10%;
    }
}

@media screen and (max-width: 800px) {
    .addClassContainerRow {
        width: 90% !important;
    }
}

@media screen and (max-width: 1390px) {
    .customFontSize {
        font-size: small;
    }
}
